var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "transparent", tile: "", elevation: "0" } },
    [
      _c(
        "v-card",
        {
          staticClass: "py-sm-8 py-4",
          attrs: { color: "light_gray", tile: "", elevation: "0" },
        },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "11", lg: "9" } }, [
                _c(
                  "span",
                  { staticClass: "text-sm-h5 text-body-1 font-weight-bold" },
                  [_vm._v(_vm._s(_vm.$t("label.cancelOrder")))]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-8", attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "11", lg: "9" } },
            [
              _c("div", [
                _c("p", { staticClass: "text-h6" }, [
                  _vm._v(_vm._s(_vm.$t("label.yourOrderHadBeenCancel"))),
                ]),
              ]),
              _c(
                "v-card",
                {
                  staticClass: "pa-6 px-sm-8 px-4 rounded-xl",
                  attrs: { color: "transparent", tile: "", elevation: "15" },
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "history-container unbox_scrollbar pr-sm-4 pr-0 mb-6",
                      },
                      [
                        _vm.order !== null
                          ? _c(
                              "div",
                              { staticClass: "mb-16" },
                              [
                                _c("app-order-summary", {
                                  attrs: { detail: _vm.order, isPayment: true },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-10" },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "5", md: "4" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "rounded-lg font-weight-bold text-body-1 py-8",
                                  attrs: {
                                    outlined: "",
                                    block: "",
                                    color: "black",
                                  },
                                  on: { click: _vm.contactUnbox },
                                },
                                [
                                  _c("v-icon", {
                                    staticClass: "stroke--black mr-2",
                                    domProps: {
                                      textContent: _vm._s("$whatappIcon"),
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("label.contactUnbox")) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "5", md: "4" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "rounded-lg font-weight-bold white--text text-body-1 py-8",
                                  attrs: { block: "", color: "black" },
                                  on: { click: _vm.goToOrderHistory },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("label.viewHistory")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }